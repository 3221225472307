<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
      strModel="Documento/Revisao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getRevisao"
      :config="{ registerName: 'Adicionar novo EPI' }"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getRevisao();
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "EPI",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Fabricante",
          sortable: true,
          collapse: 3,
          collapseLabel: "Visualizar...",
          value: "fabricantes",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{
        intTipoEquipamentoId: 1341 
      }
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "pgr.epi.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "pgr.epi.edit",
        params: {
          intId: data.intId,
        },
      });
    },

    getRevisao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get(
          "/Cadastro/Equipamento",
          this.formFiltros
        )
        .then((objResult) => {
          this.loading = false;

          if (objResult.status == 200 && objResult.result) {
            this.objPagination = objResult.result;

            let arrayRows = [];

            objResult.result.data.forEach((objEpi) => {
              let fabricantes = '<table class="table">';

              fabricantes += "<tr>";
              fabricantes += "<th>Fabricante</th>";
              fabricantes += "<th>Ca</th>";
              fabricantes += "<th>Data Valiade</th>";
              fabricantes += "</tr>";
              objEpi.fabricantes.forEach((objEpiCa) => {
                fabricantes += "<tr>";
                fabricantes +=
                  "<td>" +
                  (objEpiCa.fabricante ? objEpiCa.fabricante.strNome : "---") +
                  "</td>" +
                  "<td>" +
                  objEpiCa.strNumeroCa +
                  "</td>" +
                  "<td>" +
                  (objEpiCa.strDataValidade
                    ? this.$utilidade.toDate(objEpiCa.strDataValidade, true)
                    : "---") +
                  "</td>";
                fabricantes += "</tr>";
              });

              fabricantes += "</table>";

              let item = {
                intId: objEpi.intId,
                strNome: objEpi.strNome,
                fabricantes: !objEpi.fabricantes.length ? "---" : fabricantes,
                _item: objEpi,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          } else {
            this.$root.$message.warning("Dados inválidos");
          }
        });
    },
  },
};
</script>
